import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Spinner,
  Button,
  Col,
  Tabs,
  Tab,
} from "react-bootstrap";

// AG GRID IMPORTS
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import RentalMap from "../Reusable/RentalMap";

export default function RentalLocation(props) {

  const [rent, setRent] = useState([]);

  const [loading, setLoading] = useState(true);
  const { cityDetails } = props;

  // AG GRID
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onBtnExport = () => {
    let params = {
      fileName: `All-locations-in-${cityDetails.city}`,
    };
    gridApi.exportDataAsCsv(params);
  };
  function onFilterTextBoxChanged() {
    gridApi.setQuickFilter(document.getElementById("filter-text-box").value);
  }
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/rent/${cityDetails.city}`
    )
      .then((x) => x.json())
      .then((data) => {
        if (!data.error) {
          setRent(data.locations);
          setLoading(false);
        }
      })
      .catch((err) => {
        alert("Something went wrong!");
      });
  }, [cityDetails]);

  
  return (
    <div className="shops-container">
      <Container fluid>
        {loading ? (
          <div className="spinner">
            <Spinner animation="grow" variant="danger" />
          </div>
        ) : (
          <div className="shops-grid">
            <Tabs defaultActiveKey="list" id="uncontrolled-tab-example">
              <Tab eventKey="list" title="List">
                <div
                  className="ag-theme-alpine-dark"
                  style={{ height: 550, width: 910 }}
                >
                  {" "}
                  <Row className="search-box">
                    <Col md={9}>
                      {" "}
                      <input
                        type="text"
                        id="filter-text-box"
                        placeholder="Start Typing To filter"
                        onChange={onFilterTextBoxChanged}
                      ></input>
                    </Col>
                    <Col md={3}>
                      {" "}
                      <Button variant="info" onClick={() => onBtnExport()}>
                        Export Data as CSV
                      </Button>
                    </Col>
                  </Row>
                  <AgGridReact
                    onGridReady={onGridReady}
                    rowData={rent}
                    paginationPageSize={10}
                    pagination={true}
                  >
                     <AgGridColumn
                      width={170}
                      sortable={true}
                      field="Shop/Office/ Godown/Plot"
                      headerName="Shop/Office/ Godown/Plot"
                    ></AgGridColumn>

                    <AgGridColumn
                      width={170}
                      sortable={true}
                      field="address"
                      headerName="address"
                    ></AgGridColumn>

                    <AgGridColumn
                      width={170}
                      sortable={true}
                      field="Floor (Ground,1st,2nd 3rd)"
                      headerName="Floor"
                    ></AgGridColumn>
                    
                    <AgGridColumn
                      width={170}
                      sortable={true}
                      field="Rent"
                      headerName="Rent"
                    ></AgGridColumn>
                                     
                    <AgGridColumn
                      width={150}
                      sortable={true}
                      field="Deposit"
                      headerName="Deposit"
                    ></AgGridColumn>

                    <AgGridColumn
                      width={170}
                      sortable={true}
                      field="Facilities"
                      headerName="Facilities"
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </Tab>
              {!loading> 0 && (
                <Tab eventKey="plot" title="View List on Map">
                  <RentalMap
                  rent={rent}
                  cityDetails={cityDetails}
                  points={rent.slice(0, 10).map((locations) => {
                    return {
                      position: {
                        latitude: locations.Latitude,
                        longitude: locations.Longitude,
                      },
                      shopName: locations._id,
                    };
                  })}
                    
                    center={{
                      lon: cityDetails.longitude,
                      lat: cityDetails.latitude,
                    }}
                  />
                </Tab>
              )}
            </Tabs>
          </div>
        )}
      </Container>
    </div>
  );
}
