
import React, { useState, useEffect } from "react";
import { Container, Form, Row, Button, Modal } from "react-bootstrap";
import { setCookie } from "../../cookie";
export default function InputRawMaterial() {
  const [citiesInfo, setCitiesInfo] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [value, setValue] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false);}
  const handleShow = () => { setShow(true); }
  const onInput = ({target:{value}}) => setValue(value)
  //const onInput1 = ({target:{city}}) => setCity(city)

  const LE_ICON = window.location.origin + "/images/LE-icon.png";
// const leIcon = require(`${process.env.PUBLIC_URL}/images/LE-icon.png`);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/city`)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setCitiesInfo(data.cities);
          setSelectedCity(data.cities[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  
  
  const directToIndiaMart = () =>{
      //alert("redirecting to indiamart....... ")
      setShow(true);
      /*<Modal show ={true}>
         <Modal.Header>Hi</Modal.Header>
        <Modal.Body>Redirecting to indiamart!......</Modal.Body>
        <Modal.Footer>
          <Button variant = "secondary" onClick={handleClose}> Ok </Button>
        </Modal.Footer>
      </Modal>*/

      let cq= JSON.stringify(selectedCity)
      //setCookie('selectedCity',JSON.stringify(selectedCity),10);
      let ss = value
      const newWindow = window.open(`https://dir.indiamart.com/search.mp?ss=${ss}&prdsrc=1&cq=${cq}`, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
      //window.location = `https://dir.indiamart.com/search.mp?ss=${ss}&prdsrc=1&cq=${cq}`;
  }
  return (
    
    <div className="material-container">
      <Container fluid>
      <div>
        <img src={LE_ICON} alt="" width="10%" height="3%"/>

        </div>
        <Row>
        <div className="left-container"></div>
                 <div className="right-container">
                    <Form className="city-select-form">
                        <Form.Group>
                            <Form.Label className="select-city-label">                           
                            Select City
                            </Form.Label>
                            <Form.Control
                            as="select"
                            size="sm"
                            custom
                            onChange={(e) => {
                                setSelectedCity(citiesInfo[e.target.value]);
                            }}
                            >
                            {citiesInfo.map((cityObj, ind) => {
                                return (
                                <option value={ind} key={ind}>
                                    {cityObj.city} - {cityObj.state}
                                </option>
                                );
                            })}
                            </Form.Control>
                        </Form.Group>
                    </Form>
            
                   </div>
                   <div className="rightmost-container">
                        <Form className="raw-material-fill-form">
                            <Form.Group>
                                <Form.Label className="select-raw-material-label">
                                Raw Material
                                </Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="eg:-Floor"
                                onChange = { onInput }
                                value = { value }
                                size="sm"
                                custom
                                >
                                </Form.Control>
                            </Form.Group>
                            <Button className="proceed-button" onClick={directToIndiaMart}>Submit</Button>
                        </Form>
                </div>
      <>
      <Modal show ={ show }>
         <Modal.Header>Hi</Modal.Header>
        <Modal.Body>Redirecting to indiamart!......</Modal.Body>
        <Modal.Footer>
          <Button variant = "secondary" onClick={handleClose}> ok </Button>
        </Modal.Footer>
      </Modal>
      </>
        </Row>
   
      </Container>
    </div>
  );
}
