import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import {
  Container,
  Row,
  Spinner,
  Button,
  Col,
  Tabs,
  Tab,
} from "react-bootstrap";

// AG GRID IMPORTS
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import AroundAddressMap from "../Reusable/AroundAddressMap";

export default function ShopsAroundAddress(props) {

  const [establishments, setEstablishments] = useState([]);
  const [aroundAddress, setAroundAddress] = useState([]);
  const [loading, setLoading] = useState(true);
  const { cityDetails } = props;

  const [street, setStreet] = useState();
  const [zip, setZip] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const onInput1 = ({target:{value}}) => setStreet(value);
  const onInput2 = ({target:{value}}) => setZip(value);
  const onInput3 = ({target:{value}}) => setCity(value);
  const onInput4 = ({target:{value}}) => setState(value);

  // AG GRID
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onBtnExport = () => {
    let params = {
      fileName: `All-locations-in-${cityDetails.city}`,
    };
    gridApi.exportDataAsCsv(params);
  };
  function onFilterTextBoxChanged() {
    gridApi.setQuickFilter(document.getElementById("filter-text-box").value);
  }
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  
  let makeCall= () => {
      setLoading(true);
      fetch(
      `${process.env.REACT_APP_API_URL}/establishments/aroundAddress`, {
        method: 'put',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
        city: city,
        state: state,
        zip:zip,
        street:street,
        })
      })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
        
          setAroundAddress(data.results);
          setLatitude(data.lat);
          setLongitude(data.lng);
          console.log(`data is here ${data.lat}`);
          setLoading(false);
        }
      })
      .catch((err) => {
        alert("Something is wrong!");
        console.error(err);
        setLoading(true);
      });
    }

    useEffect(()=>{
      
      setCity(cityDetails.city);
      setState(cityDetails.state);
      makeCall();
     },[cityDetails])
  return (
    
    <div className="shops-container">
      <Container fluid>
        {loading ? (
          <div className="spinner">
            <Spinner animation="grow" variant="danger" />
          </div>
        ) : (
          <div className="shops-grid">
            <Tabs defaultActiveKey="plot" id="uncontrolled-tab-example">
             
              {!loading> 0 && (
                <Tab eventKey="plot" title="Plot">
                  <AroundAddressMap
                  aroundAddress={aroundAddress}
                  latitude ={latitude}
                  longitude={longitude}
                  cityDetails={cityDetails}
                    
                        position ={{
                          latitude: {aroundAddress}.lat,
                          longitude: {aroundAddress}.lng,
                        }
                    }
                    
                    center={{
                      lon: cityDetails.longitude,
                      lat: cityDetails.latitude,
                    }}

                  />
                </Tab>
              )}
            </Tabs>
          </div>
        )}
         <div className="bottom-container">
         <div
                  className="ag-theme-alpine-dark"
                  style={{ height: 550, width: 910 }}
                >
                  {" "}
                  
                    </div>
                        <Form className="fill-form">
                       
                          <Form.Row>
                            <Form.Group as={Col} md={5}>
                             
                                <Form.Label className="select-address-label">
                                street
                                </Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="eg:-jawahar nagar"
                                onChange = { onInput1 }
                                value = {street}
                                size="sm"
                                custom
                                >
                                </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md={5}>
                                <Form.Label className="select-zip-label">
                                ZIP code
                                </Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="eg:-335001"
                                onChange = { onInput2 }
                                value = {zip}

                                size="sm"
                                custom
                                >
                                </Form.Control>
                                </Form.Group>
                                </Form.Row>
                                
                                
                            <Button className="proceed-button"  onClick={makeCall}>Show near by shops</Button>
                        </Form>
                </div>
      </Container>
    </div>
    
  );
}
