import React, { useState, useEffect } from "react";
import { Container, Row,Spinner, Button, Form,Col,Modal } from "react-bootstrap";

// AG GRID IMPORTS
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";

export default function Shop(props) {
  const [establishments, setEstablishments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false);}
  const handleShow = () => { setShow(true); }
//   const cityDetails = JSON.parse(getCookie("selectedCity")) || null;
    const {cityDetails} = props;
  // AG GRID
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [zip, setZip] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [street, setStreet] = useState();
  const [name, setName] = useState();
  const [categoriesInfo, setCategoriesInfo] = useState([]);
  const [category, setCategory] = useState();
  const onInput1 = ({target:{value}}) => setStreet(value);
  const onInput2 = ({target:{value}}) => setZip(value);
  const onInput3 = ({target:{value}}) => setName(value);
  const onInput4 = ({target:{value}}) => setCategory(categoriesInfo[value]);


  // const [rowData, setRowData] = useState([
  //     { make: "Toyota", model: "Celica", price: 35000 },
  //     { make: "Ford", model: "Mondeo", price: 32000 },
  //     { make: "Porsche", model: "Boxter", price: 72000 }
  // ]);
    
  const onBtnExport = () => {
    // var params = getParams();
    // if (params.suppressQuotes || params.columnSeparator) {
    //   alert(
    //     'NOTE: you are downloading a file with non-standard quotes or separators - it may not render correctly in Excel.'
    //   );
    // }
    let params = {
      fileName: `All-Shops-in-${cityDetails.city}`,
    };
    gridApi.exportDataAsCsv(params);
  };
  function onFilterTextBoxChanged() {
      //gridApi.setQuickFilter()
    gridApi.setQuickFilter(document.getElementById('filter-text-box').value);
}
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  let categoryset = [];
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/subcategories`)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setCategoriesInfo(data.subcategories);
            //setCategoriesInfo(data.subcategories);
          setCategory(data.subcategories[0].category);
          for(var i=0;i>=0;i++){
            //console.log('hiii everyone');
            if(data.subcategories[i]==undefined){
              break;
            }
            categoryset.push(data.subcategories[i].category);
            console.log(categoryset[i]);
           
          }
          setCategoriesInfo.category(categoryset);
          console.log(`here is the ${categoriesInfo}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    if (cityDetails === null) {
      window.location = "/";
    } else {
      getEstablishments();
    }
  }, [cityDetails]);

  const getEstablishments = () =>{
    fetch(
      `${process.env.REACT_APP_API_URL}/establishments/${cityDetails.city}`
    )
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setEstablishments(data.establishments);
          setLoading(false);
        }
      });
  }
  const makeCall = () => {
    setShow(false);
    setLoading(true);
    
    
    fetch(
      `${process.env.REACT_APP_API_URL}/establishments/newshop`,
      {
        method: "Post",
        body: JSON.stringify({
          city: city,
          state: state,
          zip:zip,
          street:street,
          name:name,
          category:category,
        }),
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json'
        },
      }
     
    )
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          //setLoading(false);
          alert('Data succesfully Saved');
          getEstablishments();
        }
      })
      .catch((err) => {
        alert("Something is going wrong!");
        console.log(err);
        setLoading(false);
       
      });
  }
  useEffect(()=>{
      
    setCity(cityDetails.city);
    setState(cityDetails.state);
    makeCall();
    //setShow(false);
    setLoading(false);
   },[cityDetails])
  return (
    <div className="shops-container">
      <Container fluid>
        {loading ? (
            <div className="spinner">
          <Spinner animation="grow" variant="danger" />
          </div>) : (
          <div className="shops-grid">
            <div
              className="ag-theme-alpine-dark"
              style={{ height: 550, width: 910 }}
            >
              {" "}
              <Row className="search-box">
             <Col md={9}> <input type="text" id="filter-text-box" placeholder="Start Typing To filter" onChange={onFilterTextBoxChanged}></input>
             </Col><Col md={3}> <Button variant="info" onClick={() => onBtnExport()}>Export Data as CSV</Button>
             </Col>
              </Row>
              <AgGridReact
                onGridReady={onGridReady}
                rowData={establishments}
                paginationPageSize={10}
                pagination={true}
              >
                <AgGridColumn width={200} sortable={true} field="name"></AgGridColumn>
                <AgGridColumn width={100} sortable={true} filter={true} field="zip"></AgGridColumn>
                <AgGridColumn width={600} sortable={true}  field="shopAddress"></AgGridColumn>
              </AgGridReact>
            </div>
          </div>
        )}
        
         <div className="bottom-shop-container">
         <Button onClick={handleShow}>  <p> <b>ADD A NEW SHOP</b></p></Button>
         <Modal show={show}>
              <Modal.Header>Please Fill the details of the Shop to be added here....</Modal.Header>
                 <Modal.Body>
                        <Form className="fill-form">
                            
                          <Form.Row>
                            <Form.Group as={Col} md={5}>
                             
                                <Form.Label className="select-address-label">
                                street
                                </Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="eg:-jawahar nagar"
                                onChange = { onInput1 }
                                value = { street }
                                size="sm"
                                custom
                                >
                                </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md={5}>
                                <Form.Label className="select-zip-label">
                                ZIP code
                                </Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="eg:-335001"
                                onChange = { onInput2 }
                                value = { zip }
                                size="sm"
                                custom
                                >
                                </Form.Control>
                                </Form.Group>
                                </Form.Row>
                                
                                <Form.Row>
                                <Form.Group as={Col} md={5}>
                                <Form.Label className="select-city-label">
                                Name of shop
                                </Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="eg:-iconic"
                                onChange = { onInput3 }
                                value = { name }
                                size="sm"
                                custom
                                >
                                </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md={5}>
                                <Form.Label className="select-state-label">
                                category
                                </Form.Label>
                                <Form.Control
                                type="text"
                               
                                as ="select"
                                onChange = { onInput4 }
                                value = { category }
                                size="sm"
                                custom
                                >
                               
                                  {categoriesInfo.map((e, key) => {
                                    return <option key={key} value={e.value}>{e.category}</option>;
                                })}
                               
                                </Form.Control>
                            </Form.Group>
                            </Form.Row>
                            {/*<Button className="proceed-button" onClick= {makeCall}>Add the Shop</Button>*/}
                        </Form>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant = "secondary" onClick={handleClose}> Cancel </Button>
                        <Button variant = "secondary" onClick={makeCall}> Add the Shop </Button>
                        </Modal.Footer>
                        </Modal>
                </div>
      </Container>
    </div>
  );
}
