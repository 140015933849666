import React, { useState, useEffect } from "react";

// Tom tom map plotting
import tt from "@tomtom-international/web-sdk-maps";
import "@tomtom-international/web-sdk-maps/dist/maps.css";

// overlay loader import
import LoadingOverlay from "react-loading-overlay";

const circleToPolygon = require("circle-to-polygon");

export default function Map(props) {
  var map = {};
  const { points, center } = props;
  const [mapLoading, setMapLoading] = useState(true);

  const { cityDetails, prestigeStores } = props;

  useEffect(async () => {
    await plotMap();
  }, []);
  // actual map plotting function

  async function plotMap() {
    setMapLoading(true);
    var map = tt.map({
      key: process.env.REACT_APP_TOM_TOM_API_KEY,
      container: "map",
      center: center,
      dragPan: true,
      zoom: 13,
    });

    map.addControl(new tt.FullscreenControl());
    map.addControl(new tt.NavigationControl());
    map.addControl(new tt.ScaleControl());
    map.keyboard.enable();
    let colors = {
      Cloth: "#34568B",
      Educational: "#FF6F61",
      Essential_services: "#6B5B95",
      Food_stay: "#88B04B",
      Industrial_area: "#F7CAC9",
      Luxury: "#92A8D1",
      Markets: "#EFC050",
      Miscellaneous: "#E15D44",
      Residential_areas: "#D2386C",
      Service_centres: "#363945",
      Sports: "#E0B589",
      Working: "#9A8B4F",
    };

    let prestigeColors = {
      Average: "#FFFF00",
      Good: "#00FF00",
      Poor: "#FF0000",
      "Very good": "#009800",
      "Very poor": "#900000",
    };

    setMapLoading(false);

    map.on("load", function () {
      let marketcolors = [
        "#34568B",
        "#FF6F61",
        "#6B5B95",
        "#88B04B",
        "#F7CAC9",
      ];

      //Plot prestige stores
      console.log(prestigeStores.length);
      prestigeStores.forEach((store) => {
        createMarkerWithIcon(
          [store.long, store.lat],
          prestigeColors[store["salesStatus"]],
          store.franchiseName,
          "Prestige-Logo"
        );
      });

      points.forEach((market, index) => {
        drawCircles(
          index,
          market.position.longitude,
          market.position.latitude,
          500,
          marketcolors[index % 5]
        );
      });
    });

    // mapping supporting functions

    // 1
    function createMarker(position, color, popupText) {
      var markerElement = document.createElement("div");
      markerElement.className = "marker";

      var markerContentElement = document.createElement("div");
      markerContentElement.className = "marker-content";
      markerContentElement.style.backgroundColor = color;
      markerElement.appendChild(markerContentElement);

      var iconElement = document.createElement("div");
      iconElement.className = "marker-icon";
      markerContentElement.appendChild(iconElement);

      var popup = new tt.Popup({ offset: 30 }).setText(popupText);
      // add marker to map
      new tt.Marker({ element: markerElement, anchor: "bottom" })
        .setLngLat(position)
        .setPopup(popup)
        .addTo(map);
    }

    function createMarkerWithIcon(position, color, popupText, category) {
      var markerElement = document.createElement("div");
      markerElement.className = "marker";

      var markerContentElement = document.createElement("div");
      markerContentElement.className = "marker-content";
      markerContentElement.style.backgroundColor = color;
      markerElement.appendChild(markerContentElement);

      var iconElement = document.createElement("div");
      iconElement.className = "marker-icon";
      iconElement.style.backgroundImage = `url(${process.env.PUBLIC_URL}/images/${category}.png)`;

      markerContentElement.appendChild(iconElement);

      var popup = new tt.Popup({ offset: 30 }).setText(popupText);
      // add marker to map
      new tt.Marker({ element: markerElement, anchor: "bottom" })
        .setLngLat(position)
        .setPopup(popup)
        .addTo(map);
    }

    // 2
    function roundLatLng(num) {
      return Math.round(num * 1000000) / 1000000;
    }

    // 3
    function drawCircles(id, lon, lat, radius, color) {
      let coordinates = [lon, lat]; //[lon, lat]
      //let radius = radius;                           // in meters
      let numberOfEdges = 1000; //optional that defaults to 32

      let polygon = circleToPolygon(coordinates, radius, numberOfEdges);

      map.addLayer({
        id: "overlay-" + id,
        type: "fill",
        source: {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: polygon,
          },
        },
        layout: {},
        paint: {
          "fill-color": color,
          "fill-opacity": 0.4,
          "fill-outline-color": "black",
        },
      });
    }
  }
  return (
    <div>
      <LoadingOverlay active={mapLoading} spinner text="Loading Map">
        <div className="col-10 col-md-10" id="map"></div>{" "}
      </LoadingOverlay>
    </div>
  );
}
