import React, { useState, useEffect } from "react";
import { Container, Form, Row, Button } from "react-bootstrap";
import { setCookie } from "../../cookie";

export default function Home() {
  const [citiesInfo, setCitiesInfo] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/city`)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setCitiesInfo(data.cities);
          setSelectedCity(data.cities[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const proceedToCity = () =>{
      setCookie('selectedCity',JSON.stringify(selectedCity),10);
      window.location = `/shops`;
  }
  return (
    <div className="home-container">
      <Container fluid>
        <Row>
          <div className="left-container"></div>
          <div className="right-container">
            <Form className="city-select-form">
              <Form.Group>
                <Form.Label className="select-city-label">
                  Select City To Proceed
                </Form.Label>
                <Form.Control
                  as="select"
                  size="sm"
                  custom
                  onChange={(e) => {
                    setSelectedCity(citiesInfo[e.target.value]);
                  }}
                >
                  {citiesInfo.map((cityObj, ind) => {
                    return (
                      <option value={ind} key={ind}>
                        {cityObj.city} - {cityObj.state}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <Button className="proceed-button" onClick={proceedToCity}>Proceed</Button>

            </Form>
          </div>
        </Row>
      </Container>
    </div>
  );
}
