import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Spinner,
  Button,
  Col,
  Accordion,
  Alert,
  Card,
  Form,
  Tabs,
  Tab,
} from "react-bootstrap";
import {
  FaThumbsDown,
  FaThumbsUp,
  FaRegThumbsDown,
  FaRegThumbsUp,
} from "react-icons/fa";
import Swal from "sweetalert2";
import Map from "../Reusable/Map";

export default function LocationAdvisor(props) {
  const { cityDetails } = props;
  const [categories, setCategories] = useState([]);
  const [savedSchemes, setSavedSchemes] = useState([]);
  const [selectedScheme, setSelectedScheme] = useState("");
  const [loading, setLoading] = useState(true);
  const [prestigeStores, setPrestigeStores] = useState([]);

  const [response, setResponse] = useState([]);
  const handleChange = (e) => {
    setLoading(true);
    setSelectedScheme(savedSchemes[e.target.value]);
    setCategories(savedSchemes[e.target.value]["subcategories"]);
    setLoading(false);
  };
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/prestige-stores`)
      .then((x) => x.json())
      .then((data) => {
        if (!data.error) {
          setPrestigeStores(data.stores);
        }
      })
      .catch((err) => {
        alert("Something went wrong!");
      });
  }, []);
  const getPredefinedAdvise = () => {
    let schema = { schema: selectedScheme["subcategories"] };
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/establishments/locationAdvisor?cityName=${cityDetails.city}`,
      {
        method: "PUT",
        body: JSON.stringify(schema),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((x) => x.json())
      .then((data) => {
        if (!data.error) {
          setResponse(data.marketAreasResult);
          if (data.schemaMessage) {
            getInitialSchemes();
            Swal.fire({
              // position: 'top-end',
              icon: "success",
              title: data.schemaMessage,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          if (data.message !== null) {
            alert(data.message);
          } else {
            alert("sorry something went wrong!");
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        alert("sorry something went wrong!");
        setLoading(false);
      });
  };
  const makeAdviseCall = () => {
    Swal.fire({
      title: "Do you want to save this?",
      text: "Do you want to save this query as predefined scheme?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, please do!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { value: schemeName } = await Swal.fire({
          input: "text",
          inputLabel: "Please give scheme a name",
          inputPlaceholder: "Scheme name",
        });

        if (schemeName) {
          // Swal.fire(`Entered URL: ${url}`)
          let schema = { schema: categories, schemaName: schemeName };
          setLoading(true);
          fetch(
            `${process.env.REACT_APP_API_URL}/establishments/locationAdvisor?cityName=${cityDetails.city}`,
            {
              method: "PUT",
              body: JSON.stringify(schema),
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then((x) => x.json())
            .then((data) => {
              if (!data.error) {
                setResponse(data.marketAreasResult);
                if (data.schemaMessage) {
                  getInitialSchemes();
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: data.schemaMessage,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              } else {
                if (data.message !== null) {
                  alert(data.message);
                } else {
                  alert("sorry something went wrong!");
                }
              }
              setLoading(false);
            })
            .catch((err) => {
              alert("sorry something went wrong!");
              setLoading(false);
            });
        }
      } else {
        // Swal.fire(`Entered URL: ${url}`)
        let schema = { schema: categories };
        setLoading(true);
        fetch(
          `${process.env.REACT_APP_API_URL}/establishments/locationAdvisor?cityName=${cityDetails.city}`,
          {
            method: "PUT",
            body: JSON.stringify(schema),
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((x) => x.json())
          .then((data) => {
            if (!data.error) {
              setResponse(data.marketAreasResult);
              if (data.schemaMessage) {
                getInitialSchemes();
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: data.schemaMessage,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            } else {
              if (data.message !== null) {
                alert(data.message);
              } else {
                alert("sorry something went wrong!");
              }
            }
            setLoading(false);
          })
          .catch((err) => {
            alert("sorry something went wrong!");
            setLoading(false);
          });
      }
    });
  };
  const resetQuery = () => {
    getInitialSchemes();
    getInitialSubcategories();
  };
  const getInitialSubcategories = () => {
    setLoading(true);
    setResponse([]);
    fetch(`${process.env.REACT_APP_API_URL}/subcategories`)
      .then((x) => x.json())
      .then((data) => {
        if (!data.error) {
          setCategories(data.subcategories);
          setLoading(false);
        }
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    getInitialSubcategories();
  }, [cityDetails]);

  const getInitialSchemes = () => {
    fetch(`${process.env.REACT_APP_API_URL}/schemes`)
      .then((x) => x.json())
      .then((data) => {
        if (!data.error) {
          setSavedSchemes(data.laSchemes);
          if (data.laSchemes.length > 0 && selectedScheme === "") {
            setSelectedScheme(data.laSchemes[0]);
          }
          setLoading(false);
        }
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    getInitialSchemes();
  }, [cityDetails]);

  return (
    <div className="location-advisor-container">
      <Container fluid>
        {loading ? (
          <div className="spinner">
            <Spinner animation="grow" variant="danger" />
          </div>
        ) : (
          <div className="advisor-space">
            <Row>
              <h3 className="page-title">Location Advisor</h3>
            </Row>
            <Row>
              <Col md={4}>
              <label for="scheme">Pick from saved schemes</label>
              </Col><Col md={4}><select
                as="select"
                size="sm"
                custom
                disabled={savedSchemes.length === 0 ? true : false}
                onChange={handleChange}
                placeholder={
                  savedSchemes.length === 0
                    ? "No saved schemes to show"
                    : "Pick amongst one of the saved schemes"
                }
              >
                {savedSchemes.map((scheme, index) => {
                  return (
                    <option value={index} key={index}>
                      {scheme.schemaName}
                    </option>
                  );
                })}
              </select>{" "}</Col>
              <Col md={4}>
                <Button
                  size={"sm"}
                  variant={savedSchemes.length === 0 ? "secondary" : "success"}
                  disabled={savedSchemes.length === 0 ? true : false}
                  onClick={getPredefinedAdvise}
                >
                  Look for advised locations
                </Button>
              </Col>
            </Row>

            {categories.length > 0 && response.length === 0 ? (
              <React.Fragment>
                {" "}
                <Container fluid>
                  {" "}
                  <Accordion /*defaultActiveKey="0"*/>
                    {categories.map((category, index) => {
                      return (
                        <Card>
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey={index.toString()}
                          >
                            {category.category}
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={index.toString()}>
                            <Card.Body>
                              {category.subcategories.map(
                                (subcategory, subIndex) => {
                                  return (
                                    <Alert
                                      key={subIndex}
                                      variant={
                                        subcategory.selected
                                          ? "primary"
                                          : "secondary"
                                      }
                                    >
                                      <Row className="subcategory-card">
                                        {" "}
                                        <span>
                                          <Col>
                                            <input
                                              type="checkbox"
                                              checked={subcategory.selected}
                                              onChange={() => {
                                                let clone = categories;
                                                clone[index]["subcategories"][
                                                  subIndex
                                                ]["selected"] = !clone[index][
                                                  "subcategories"
                                                ][subIndex]["selected"];
                                                setCategories([...clone]);
                                              }}
                                            />
                                          </Col>
                                        </span>
                                        <Col>{subcategory.subcategory}</Col>
                                        <Col>
                                          {subcategory.selected ? (
                                            subcategory.disabler ? (
                                              <Row>
                                                <Col>
                                                  <FaRegThumbsUp
                                                    onClick={() => {
                                                      let clone = categories;

                                                      clone[index][
                                                        "subcategories"
                                                      ][subIndex][
                                                        "disabler"
                                                      ] = !clone[index][
                                                        "subcategories"
                                                      ][subIndex]["disabler"];
                                                      console.log(clone);
                                                      setCategories([...clone]);
                                                    }}
                                                  />
                                                </Col>
                                                &nbsp;
                                                <Col>
                                                  {" "}
                                                  <FaThumbsDown />
                                                </Col>
                                              </Row>
                                            ) : (
                                              <Row>
                                                <Col>
                                                  <FaThumbsUp />
                                                </Col>
                                                &nbsp;
                                                <Col>
                                                  <FaRegThumbsDown
                                                    onClick={() => {
                                                      let clone = categories;
                                                      clone[index][
                                                        "subcategories"
                                                      ][subIndex][
                                                        "disabler"
                                                      ] = !clone[index][
                                                        "subcategories"
                                                      ][subIndex]["disabler"];
                                                      setCategories([...clone]);
                                                    }}
                                                  />
                                                </Col>
                                              </Row>
                                            )
                                          ) : null}
                                        </Col>
                                        {subcategory.selected ? (
                                          <Col>
                                            <label for="radius">
                                              Radius (in metres)
                                            </label>
                                            <input
                                              name="radius"
                                              type="number"
                                              value={subcategory.radius}
                                              min={500}
                                              max={5000}
                                              onChange={(e) => {
                                                let clone = categories;
                                                clone[index]["subcategories"][
                                                  subIndex
                                                ]["radius"] = e.target.value;
                                                setCategories([...clone]);
                                              }}
                                            />
                                          </Col>
                                        ) : null}
                                      </Row>
                                    </Alert>
                                  );
                                }
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      );
                    })}
                  </Accordion>
                </Container>
                <Row>
                  <Button
                    variant="warning"
                    style={{ float: "right" }}
                    onClick={makeAdviseCall}
                  >
                    Advise Locations
                  </Button>
                </Row>
              </React.Fragment>
            ) : response.length > 0 ? (
              <Container fluid>
                <Row>
                  <Col>
                  {/* <h3>Top Locations sugestions for the Coaching Centres in Bhagalpur</h3> */}

                    <h3>Top Locations sugestions for the query</h3>
                  </Col>
                  <Col>
                    <Button onClick={resetQuery}>Reset Query</Button>
                  </Col>
                </Row>
                <Tabs defaultActiveKey="list" id="uncontrolled-tab-example">
                  <Tab eventKey="list" title="List">
                    <Accordion>
                      {response.map((resp, index) => {
                        return resp.areaScore > 0 ? (
                          <Card key={index}>
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey={index.toString()}
                            >
                              # {index + 1} {resp._id} - Score :{" "}
                              {resp.areaScore}
                            </Accordion.Toggle>
                            {
                              <Accordion.Collapse eventKey={index.toString()}>
                                <Card.Body>
                                  {resp.scoreArray.map((category) => {
                                    return (
                                      <Alert
                                        variant={
                                          category.disabler
                                            ? "danger"
                                            : "success"
                                        }
                                      >
                                        There are{" "}
                                        {category.score / category.weight}{" "}
                                        {category.subcategory.replace(
                                          /^\w/,
                                          (c) => c.toUpperCase()
                                        )}
                                        (s) in {category.radius} metres radius
                                        around this place{" "}
                                      </Alert>
                                    );
                                  })}
                                </Card.Body>
                              </Accordion.Collapse>
                            }
                          </Card>
                        ) : null;
                      })}
                    </Accordion>
                  </Tab>
                  {!loading && response.length > 0 && (
                    <Tab eventKey="plot" title="Plot">
                      <Map
                        prestigeStores={prestigeStores}
                        cityDetails={cityDetails}
                        points={response.slice(0, 10).map((market) => {
                          return {
                            position: {
                              latitude: market.avgLat,
                              longitude: market.avgLon,
                            },
                            shopName: market._id,
                          };
                        })}
                        center={{
                          lon: cityDetails.longitude,
                          lat: cityDetails.latitude,
                        }}
                      />
                    </Tab>
                  )}
                </Tabs>
              </Container>
            ) : null}
          </div>
        )}
      </Container>
    </div>
  );
}
