import React, { useState, useEffect } from "react";
import { Container, Row,Spinner, Button, Col } from "react-bootstrap";

// AG GRID IMPORTS
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import axios from "axios";

export default function GovernmentSchemes(props) { 
  const [agarbatti, setAgarbatti] = useState([]);
  const [loading, setLoading] = useState(true);
//   const cityDetails = JSON.parse(getCookie("selectedCity")) || null;

    const {trainingDetails} = props;
  // AG GRID
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  // const [rowData, setRowData] = useState([
  //     { make: "Toyota", model: "Celica", price: 35000 },
  //     { make: "Ford", model: "Mondeo", price: 32000 },
  //     { make: "Porsche", model: "Boxter", price: 72000 }
  // ]);
    
  const onBtnExport = () => {
    // var params = getParams();
    // if (params.suppressQuotes || params.columnSeparator) {
    //   alert(
    //     'NOTE: you are downloading a file with non-standard quotes or separators - it may not render correctly in Excel.'
    //   );
    // }
    let params = {
      fileName: `TrainingSheet1.csv`,
    };
    gridApi.exportDataAsCsv(params);
  };
  function onFilterTextBoxChanged() {
      //gridApi.setQuickFilter()
    gridApi.setQuickFilter(document.getElementById('filter-text-box').value);
}
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    console.log('grid is ready');
  };

  const Fetchdataa = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/agarbatti`);
    console.log(res.data);
    setAgarbatti(res.data);
    setLoading(false);
  }

  useEffect(() => {
    Fetchdataa();
    console.log({agarbatti});
  }, []);
  
  return (
    <div className="shops-container">
      <Container fluid>
        {loading ? (
            <div className="spinner">
          <Spinner animation="grow" variant="danger" />
          </div>) : (
          <div className="shops-grid">
            <div
              className="ag-theme-alpine-dark"
              style={{ height: 550, width: 910 }}
            >
              {" "}
              <Row className="search-box" rowHeight="50">
             <Col md={9}> <input type="text" id="filter-text-box" placeholder="Start Typing To filter" onChange={onFilterTextBoxChanged}></input>
             </Col><Col md={3}> <Button variant="info" onClick={() => onBtnExport()}>Export Data as CSV</Button>
             </Col>
              </Row>
              <AgGridReact
                onGridReady={onGridReady}
                rowData={agarbatti}
                paginationPageSize={10}
                pagination={true}
              >
                <AgGridColumn width={100} sortable={true} filter={true} field="Idea"></AgGridColumn>
                <AgGridColumn width={100} sortable={true} filter={true} field="Sno"></AgGridColumn>
                <AgGridColumn width={100} sortable={true} filter={true} field="CentralState"></AgGridColumn>
                <AgGridColumn width={100} sortable={true} filter={true} field="Organization Conducting the training"></AgGridColumn>
                <AgGridColumn width={100} sortable={true} filter={true} field="Central/State"></AgGridColumn>
                <AgGridColumn width={100} sortable={true} filter={true} field="ProgramName"></AgGridColumn>
                <AgGridColumn width={100} sortable={true} filter={true} field="Curriculum"></AgGridColumn>
                <AgGridColumn width={600} sortable={true}  field="Duration"></AgGridColumn>
                <AgGridColumn width={600} sortable={true}  field="Eligibility"></AgGridColumn>
                <AgGridColumn width={600} sortable={true}  field="Contact"></AgGridColumn>
                <AgGridColumn width={600} sortable={true}  field="Digital/Physical"></AgGridColumn>
                <AgGridColumn width={600} sortable={true}  field="Application Form"></AgGridColumn>
                <AgGridColumn width={100} sortable={true} filter={true} field="Links"></AgGridColumn>
                <AgGridColumn width={100} sortable={true} filter={true} field="Cost"></AgGridColumn>
                <AgGridColumn width={100} sortable={true} filter={true} field="Financial Assistance"></AgGridColumn>


              
              </AgGridReact>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}
