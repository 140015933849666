import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Spinner,
  Button,
  Col,
  Tabs,
  Tab,
} from "react-bootstrap";

// AG GRID IMPORTS
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import Map from "../Reusable/Map";
export default function TopMarketAreas(props) {
  const [topMarketAreas, setTopMarketAreas] = useState([]);
  const [prestigeStores, setPrestigeStores] = useState([]);

  const [loading, setLoading] = useState(true);
  const { cityDetails } = props;

  // AG GRID
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onBtnExport = () => {
    let params = {
      fileName: `All-Shops-in-${cityDetails.city}`,
    };
    gridApi.exportDataAsCsv(params);
  };
  function onFilterTextBoxChanged() {
    gridApi.setQuickFilter(document.getElementById("filter-text-box").value);
  }
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/prestige-stores`
    )
      .then((x) => x.json())
      .then((data) => {
        if (!data.error) {
          setPrestigeStores(data.stores);
        }
      })
      .catch((err) => {
        alert("Something went wrong!");
      });
  }, []);

  useEffect(() => {
    if (cityDetails === null) {
      window.location = "/";
    } else {
      fetch(
        `${process.env.REACT_APP_API_URL}/establishments/top-market-areas/${cityDetails.city}`
      )
        .then((data) => data.json())
        .then((data) => {
          if (!data.error) {
            setTopMarketAreas(data.topMarketAreas);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [cityDetails]);
  return (
    <div className="shops-container">
      <Container fluid>
        {loading ? (
          <div className="spinner">
            <Spinner animation="grow" variant="danger" />
          </div>
        ) : (
          <div className="shops-grid">
            <Tabs defaultActiveKey="list" id="uncontrolled-tab-example">
              <Tab eventKey="list" title="List">
                <div
                  className="ag-theme-alpine-dark"
                  style={{ height: 550, width: 910 }}
                >
                  {" "}
                  <Row className="search-box">
                    <Col md={9}>
                      {" "}
                      <input
                        type="text"
                        id="filter-text-box"
                        placeholder="Start Typing To filter"
                        onChange={onFilterTextBoxChanged}
                      ></input>
                    </Col>
                    <Col md={3}>
                      {" "}
                      <Button variant="info" onClick={() => onBtnExport()}>
                        Export Data as CSV
                      </Button>
                    </Col>
                  </Row>
                  <AgGridReact
                    onGridReady={onGridReady}
                    rowData={topMarketAreas}
                    paginationPageSize={10}
                    pagination={true}
                  >
                    <AgGridColumn
                      width={770}
                      sortable={true}
                      field="_id"
                      headerName="Address"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={130}
                      sortable={true}
                      filter={true}
                      field="shopCounts"
                      headerName="Shops"
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </Tab>
              {!loading && topMarketAreas.length > 0 && (
                <Tab eventKey="plot" title="Plot">
                  <Map
                  prestigeStores={prestigeStores}
                  cityDetails={cityDetails}
                    points={topMarketAreas.slice(0, 10).map((market) => {
                      return {
                        position: {
                          latitude: market.avgLat,
                          longitude: market.avgLon,
                        },
                        shopName: market._id,
                      };
                    })}
                    center={{
                      lon: cityDetails.longitude,
                      lat: cityDetails.latitude,
                    }}
                  />
                </Tab>
              )}
            </Tabs>
          </div>
        )}
      </Container>
    </div>
  );
}
