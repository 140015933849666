import React from "react";
import { Nav, Dropdown,DropdownButton } from "react-bootstrap";
import { withRouter } from "react-router";

const LE_ICON = window.location.origin + "/images/LE-icon.png";
// const leIcon = require(`${process.env.PUBLIC_URL}/images/LE-icon.png`);
const Side = (props) => {
  return (
    <>
      <Nav
        className="col-md-12 d-none d-md-block bg-success sidebar"
        activeKey={window.location.pathname}
      >
        <div className="sidebar-sticky">
        <img src={LE_ICON} alt="" width="100%" height="30%"/>

        </div>
        <Nav.Item>
          <Nav.Link href="/" disabled={window.location.pathname === "/"}>
            Home
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            href="/shops"
            disabled={window.location.pathname === "/shops"}
          >
            Shops
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/InputRawMaterial" disabled={window.location.pathname === "/InputRawMaterial"}>
            Purchase Raw Material
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            href="/shops-around"
            disabled={window.location.pathname === "/shops-around"}
          >
            Shops Around Address
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            href="/top-market-areas"
            disabled={window.location.pathname === "/top-market-areas"}
          >
            Market Areas
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Nav.Link
            href="/location-advisor"
            disabled={window.location.pathname === "/location-advisor"}
          >
            Location Advisor
            </Nav.Link>
            </Nav.Item>
            <Nav.Item>
          <Nav.Link href="/rental-location" disabled={window.location.pathname === "/rental-location"}>
            Rental Locations
          </Nav.Link>
        </Nav.Item>
        {/* <Nav.Item> */}
        
          {/* <Nav.Link>          */}
            <Dropdown>
              <Dropdown.Toggle>New Business Idea</Dropdown.Toggle>
              <Dropdown.Menu>
              <Dropdown.Item href="/training">Training</Dropdown.Item>
              <Dropdown.Item href="/required-license">Required License</Dropdown.Item>
              <Dropdown.Item href="/government-schemes">Government Schemes</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            

          {/* </Nav.Link> */}
         
        {/* </Nav.Item> */}
      </Nav>
    </>
  );
};
const Sidebar = withRouter(Side);
export default Sidebar;
