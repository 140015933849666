import React, { useState, useEffect } from "react";

// Tom tom map plotting
import tt from "@tomtom-international/web-sdk-maps";
import "@tomtom-international/web-sdk-maps/dist/maps.css";

// overlay loader import
import LoadingOverlay from "react-loading-overlay";

const circleToPolygon = require("circle-to-polygon");


export default function RentalMap(props) {
  var map = {};
  const { points, center } = props;
  const [mapLoading, setMapLoading] = useState(true);

  const { cityDetails, rent } = props;

  useEffect(async () => {
    await plotMap();
  }, []);
  // actual map plotting function

  async function plotMap() {
    setMapLoading(true);
    var map = tt.map({
      key: process.env.REACT_APP_TOM_TOM_API_KEY,
      container: "map",
      center: center,
      dragPan: true,
      zoom: 13,
    });

    map.addControl(new tt.FullscreenControl());
    map.addControl(new tt.NavigationControl());
    map.addControl(new tt.ScaleControl());
    map.keyboard.enable();
    
    let rentColors = {
      "Semi Furnished": "#FFFF00",
      "Furnished": "#00FF00",
      "Unfurnished": "#FF0000",
      " ": "#900000",
    };

    setMapLoading(false);

    map.on("load", function () {
      let marketcolors = [
        "#34568B",
        "#FF6F61",
        "#6B5B95",
        "#F7CAC9",
      ];

      //Plot rental locations
      console.log(rent);
      rent.forEach((locations) => {
        createMarkerWithIcon(
          [locations.Longitude, locations.Latitude],
          rentColors[locations["Facilities"]],
          locations.Facilities,
          locations.Rent,
        );
      });

      rent.forEach((locations) => {
        console.log(locations.Latitude)
        /*drawCircles(
          locations._id,
          locations.Longitude,
          locations.Latitude,
          
      
          500,
          marketcolors[24 % 4]
        );*/
        let color = "black"
        if(locations['Rent']<='15000'){color="blue";}
        else if(locations['Rent']<="30000"){color="red";}
         else if(locations['Rent']<="50000"){color="brown";}
        else{color="green";}

        let position = [locations.Longitude, locations.Latitude],popupText=`${locations['Shop/Office/ Godown/Plot']},rent=${locations.Rent},SuperArea(sq ft) = ${locations['Super Area (Sq ft)']}`
        createMarkerWithIcon(position, color, popupText)
      });

    });

    // mapping supporting functions

    // 1
    function createMarker(position, color, popupText) {
      var markerElement = document.createElement("div");
      markerElement.className = "marker";

      var markerContentElement = document.createElement("div");
      markerContentElement.className = "marker-content";
      markerContentElement.style.backgroundColor = color;
      markerElement.appendChild(markerContentElement);

      var iconElement = document.createElement("div");
      iconElement.className = "marker-icon";
      markerContentElement.appendChild(iconElement);

      var popup = new tt.Popup({ offset: 30 }).setText(popupText);
      // add marker to map
      new tt.Marker({ element: markerElement, anchor: "bottom" })
        .setLngLat(position)
        .setPopup(popup)
        .addTo(map);
    }

    function createMarkerWithIcon(position, color, popupText) {
      var markerElement = document.createElement("div");
      markerElement.className = "marker";

      var markerContentElement = document.createElement("div");
      markerContentElement.className = "marker-content";
      markerContentElement.style.backgroundColor = color;
      markerElement.appendChild(markerContentElement);

      var iconElement = document.createElement("div");
      iconElement.className = "marker-icon";
      //iconElement.style.backgroundImage = `url(${process.env.PUBLIC_URL}/images/${category}.png)`;

      markerContentElement.appendChild(iconElement);

      var popup = new tt.Popup({ offset: 30 }).setText(popupText);
      // add marker to map
      new tt.Marker({ element: markerElement, anchor: "bottom" })
        .setLngLat(position)
        .setPopup(popup)
        .addTo(map);
    }

    // 2
    function roundLatLng(num) {
      return Math.round(num * 1000000) / 1000000;
    }

    // 3
    function drawCircles(id, lon, lat, radius, color) {
      let coordinates = [lon, lat]; //[lon, lat]
      //let radius = radius;                           // in meters
      let numberOfEdges = 1000; //optional that defaults to 32

      let polygon = circleToPolygon(coordinates, radius, numberOfEdges);

      map.addLayer({
        id: "overlay-" + id,
        type: "fill",
        source: {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: polygon,
          },
        },
        layout: {},
        paint: {
          "fill-color": color,
          "fill-opacity": 0.4,
          "fill-outline-color": "black",
        },
      });
    }
  }
  return (
    <div>
      <LoadingOverlay active={mapLoading} spinner text="Loading Map">
        <div className="col-10 col-md-10" id="map"></div>{" "}
      </LoadingOverlay>
    </div>
  );
}
