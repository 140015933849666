// import "./App.css";
// import React from "react";
// import Home from "./Components/Home/Home";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// import Shop from "./Components/Shops/Shop";
// import Sidebar from "./Components/SideBar/SideBar";
// import { Row, Container } from "react-bootstrap";

// const defaultRoute = (component) =>{
//   return <Container fluid>
//     <Row>
//     <Col md={6}>

//     </Col>
//     <Col
//     </Row>
//   </Container>
// }
// export default function App() {
//   return (
//     <Router>
//       <Switch>
//         <Route exact path="/shops">
//           <Sidebar/>
//           <Shop />
//         </Route>
//         <Route exact path="/">
//           <Home />
//         </Route>
//       </Switch>
//     </Router>
//   );
// }

import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Route as DefaultRoute,
  Switch,
} from "react-router-dom";
import Home from "./Components/Home/Home";
import InputRawMaterial from "./Components/InputRawMaterial/InputRawMaterial";
import GovernmentSchemes from "./Components/GovernmentSchemes/GovernmentSchemes";
import Training from "./Components/Training/Training";
import LicenseRequired from "./Components/LicenseRequired/LicenseRequired";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Shop from "./Components/Shops/Shop";
import Sidebar from "./Components/SideBar/SideBar";
import { Row, Container, Col } from "react-bootstrap";
import { getCookie } from "./cookie";
import TopMarketAreas from "./Components/TopMarketAreas/TopMarketAreas";
import RentalLocation from "./Components/RentalLocation/RentalLocation";
import Header from "./Components/Header/Header";
import LocationAdvisor from "./Components/LocationAdvisor/LocationAdvisor";
import ShopsAroundAddress from "./Components/ShopsAroundAddress/ShopsAroundAddress";
import axios from "axios";

const DefaultLayout = ({ children }) => (
  <Fragment>
    {/* <Header /> */}
    <Container fluid>
      <Row>
        <Col md={2}>
          <Sidebar />
        </Col>
        <Col md={10}>
          <Row>
            <Header />
          </Row>
          <Row>{children}</Row>
        </Col>
      </Row>
    </Container>
  </Fragment>
);

const HomePageLayout = ({ children }) => <Fragment>{children}</Fragment>;
const Route = ({
  component: Component,
  layout: Layout = DefaultLayout,
  ...rest
}) => {
  return (
    <DefaultRoute
      {...rest}
      render={(props) => (
        <Layout>
          <Component
            {...props}
            cityDetails={JSON.parse(getCookie("selectedCity")) || null}
          />
        </Layout>
      )}
    />
  );
};

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Home} layout={HomePageLayout} />
      <Route exact path="/InputRawMaterial" component={InputRawMaterial} />
      <Route path="/shops" component={Shop} />
      <Route path="/top-market-areas" component={TopMarketAreas} />
      <Route path="/rental-location" component={RentalLocation} />
      <Route path="/government-schemes" component={GovernmentSchemes} />
      <Route path="/training" component={Training} />
      <Route path="/required-license" component={LicenseRequired} />
      <Route path="/shops-around" component={ShopsAroundAddress} />
      <Route path="/location-advisor" component={LocationAdvisor} />

      {/* <Route path="/favourites" component={Home} />
      <Route path="/my-bids" component={Home} />
      <Route path="/about" component={Home} />
      <Route path="/how-it-works" component={Home} />
      <Route path="/name/:name" component={SingleName} /> */}
    </Switch>
  </Router>
);

export default App;
